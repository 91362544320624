<template>
  <v-dialog :value="true" persistent scrollable>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="error" class="mb-2">
          <v-btn icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
          <v-toolbar-title>Firma</v-toolbar-title>
          <v-spacer />
          <v-btn @click="back" icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-title
                  ><v-icon>mdi-office-building</v-icon> Firma</v-card-title
                >
                <v-list-item>
                  <v-text-field
                    v-model="company.companyName"
                    label="Name"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.homepage"
                    label="Homepage"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.emailCompany"
                    label="E-Mail"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.phoneCompany"
                    label="Telefon"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title
                  ><v-icon>mdi-account-tie</v-icon> Kontakt</v-card-title
                >
                <v-list-item>
                  <v-text-field
                    v-model="company.contactName1"
                    label="Name 1"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.contactName2"
                    label="Name 2"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.emailContact"
                    label="E-Mail"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.phoneContact"
                    label="Telefon"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              ><v-card outlined>
                <v-card-title
                  ><v-icon>mdi-comment</v-icon> Kommentar</v-card-title
                >
                <v-list-item>
                  <v-textarea
                    label="Kommentar"
                    v-model="company.comment"
                  ></v-textarea>
                </v-list-item> </v-card
            ></v-col>
            <v-col>
              <v-card outlined>
                <v-card-title><v-icon>mdi-map</v-icon> Anschrift</v-card-title>
                <v-list-item>
                  <v-text-field
                    v-model="company.address1"
                    label="Zeile 1"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.address2"
                    label="Zeile 2"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.postcode"
                    label="PLZ"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.town"
                    label="Ort"
                  ></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="company.country"
                    label="Land"
                  ></v-text-field>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn disabled text color="error"
          ><v-icon left>mdi-delete</v-icon> Löschen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InventoryCompanyEdit',
  props: ['id'],
  data: () => ({
    loading: false,
    saving: false,
    search: '',
    company: {},
  }),
  methods: {
    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: 'InventoryCompanies',
      });
    },
    async getData() {
      if (this.id) {
        this.loading = true;
        this.company = await this.apiGet({
          resource: 'inventory/company',
          id: this.id,
        });
        this.loading = false;
      }
    },
    async save() {
      this.saving = true;
      await this.apiSave({
        create: this.id == 0,
        resource: 'inventory/company',
        data: this.company,
      });
      this.saving = false;
      this.$root.showSuccess('Firma erfolgreich gespeichert!');
      this.$router.back();
    },
  },
  async created() {
    this.getData();
  },
};
</script>
